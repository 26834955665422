body {
   
font-family: 'Open Sans', sans-serif;
letter-spacing: 0.05rem;
}
h1 {
    font-family: 'Dancing Script', cursive;
    line-height: 1,5em;
   text-shadow: .5px .5px 1px rgb(7, 7, 7);
}


h4, h5, h6 {
  font-family: 'Sansita Swashed', cursive;
  
    font-weight: lighter;
}

p {
  font-family: 'Open Sans', sans-serif;
    font-style: italic;
}
 .btn {
   background-color: rgb(129, 127, 255);
 }


#logo {
  width: 30px;
  margin-right: 15px;
}



 /* section unde si cand*/
#geolocal {
  background-image: linear-gradient(90deg, #0C4D77 , #78c8d6 95%);
}
#geolocal h1 {
  color: aliceblue;
}

  /* section unde si cand*/



  /* css blog*/

.main-row {
 
    margin-bottom: 8%;
    background-color:rgba(255, 255, 255, 0.705);
    box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
    border-radius: 0.5rem;
    
  }
  
  .blog-img > img {
   width: 250px;
   transform: translateY(-30px);
   border-radius: 50%;
   box-shadow: 0 0 8px 3px rgba(0,0,0,.3);
  }
  .blog-title > h3 {
    line-height: 2rem;
  }
  @media (min-width: 500px) {
  .blog-title > h3 {
    line-height: 3rem;
  }
 
  }
  @media (min-width: 1100px) {
    .main-row {
      max-width: 800px;
      position: relative;
      left: 15%;
    }
    }
 .lang_img {
  width: 40px;
 }
  
    /*dress code*/
    .circle {
      position: relative;
      margin: 50px auto 50px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70vh;
      
      
  }
  
 .circle__round {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
  }
  
  .circle__round::before {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(0, 0, 0, .1);
  
  }
  
  .circle__img {
      position: absolute;
      left: 50%;
      top: 50%;
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
  }

  .circle__round-text{
     background-color: white;
      border-radius: 100%;
      padding: 10%;
      width: 58vh;
      height: 58vh;
      opacity: 0.7;
  }
 
  
  .circle__text {
    position: absolute;
      z-index: 10;
      text-align: center;
      color: #00412D;
  }
  .circle__text-sus{
    position: absolute;
    z-index: 10;
    text-align: center;
    color: white;
    background-color: #00412D;
    border-radius: 5px;
   max-width: 320px;
   top: 9em;

    
  }
  .circle__text-jos{
    position: absolute;
    z-index: 10;
    text-align: center;
    color: black;
    max-width: 220px;
    top: 22em;
  }
 
    .color {
      list-style-type: none;
      position: absolute;
      left: 640px;
    }
  
    .color li{
      display: inline-block;
     margin: 5px;
      width: 5vh;
    height: 5vh;
    border-radius: 100%;
      }
  .color .galben{
    background-color: #FFFF6D;
  }
  .color .pink{
    background-color: #F12F8D;
  }
  .color .green{
    background-color: #2FCF39;
  }
  .color .orange{
    background-color: #FF692A;
  }
  .color .blue{
    background-color: #274AFC;
  }
  .color .red{
    background-color: #E61211;
  }
  .color .violet{
    background-color: #90129A;
  }

 /* @media (max-width: 500px) {
    .circle__round-text{
       width: 45vh ;
    height: 45vh;
    }
    .circle__text-sus{
      top: 150px;
      width: 250px;
    }
    .circle__text-jos{
      width: 200px;
      bottom: 90px;
    }
    .color li {
      top: 70px;
     margin: 5px;
      width: 4vh;
    height: 4vh;
    }
  }*/

  /*@media (max-width: 420px) {
    .circle__round-text{
       width: 45vh ;
    height: 45vh;
    }
    .circle__text-sus{
      top: 180px;
      width: 200px;
    }
    .circle__text-jos{
      width: 200px;
      bottom: 100px;
    }
    .color li {
      top: 75px;
     margin: 5px;
      width: 4vh;
    height: 4vh;
    }
  }*/
 
   /* .circle {
      position: relative;
      opacity: 0.7; 
      background-color: white;
      width: 550px ;
      height: 550px; 
      border-radius: 100%;
      justify-content: center;
      align-items: center;
    }
    .circle__text {
      background-color: green;
       position: absolute;
      top: 100px;
                         
                        font-size: 15px; 
                        left: 105px;
    }
    @media (max-width: 550px) {
      .circle{
         width: 250px ;
      height: 250px;
      }
      .circle__text {
         
                          font-size: 5px; 
                          
      }
    }*/